export default function authorizedMiddleware({
  to,
  next,
  store,
  nextMiddleware,
}) {
  console.info("<authorizedMiddleware> isAuth: ", store.getters.isAuth);

  const user = JSON.parse(localStorage.getItem("user"));

  if (!user) {
    store.state.token = "";
    return next({ name: "common.login" });
  }

  const hasNoGroup = Object.values(user.groups.product_access).every((v => !v))
    && Object.values(user.groups.product_services).every((v => v.length === 0));

  const dashboardWhiteList =
    ["dashboard.home", "dashboard.analytics.list", "dashboard.team.management"];

  if (hasNoGroup && !dashboardWhiteList.includes(to.name)) {
    return next("/not-found");
  }

  if (store.getters.isAuth) {
    return nextMiddleware();
  }

  return next({
    name: "common.login",
    query: { redirect: to.fullPath },
  });
}
