<script>
import { validate } from "vee-validate";
import MazPhoneNumberInput from "maz-ui/components/MazPhoneNumberInput";

export default {
  name: "SnsInput",
  components: {  MazPhoneNumberInput },
  props: {
    modelValue: {
      required: true,
    },
    size: {
      type: String,
      default: "md",
    },
    label: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "",
    },
    // todo make this array
    validationRule: {
      type: String,
      default: "",
    },
    inputType: {
      type: String,
      default: "textfield",
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue", "isValid", "getCountryCode"],
  data() {
    return {
      validatorError: null,
      isPasswordVisible: false,
    };
  },
  computed: {
    type() {
      let { type } = this.$attrs;

      if (this.$attrs.type === "password") {
        type = this.isPasswordVisible ? "text" : "password";
      }

      return type;
    },
  },
  // todo refactor validation
  methods: {
    validateField() {
      if (this.validationRule !== "" || this.required) {
        validate(this.modelValue, `${this.required ? "required|" : ""}${this.validationRule}`).then(
          (result) => {
            if (result.valid) {
              this.validatorError = null;
            } else {
              [this.validatorError] = result.errors;
            }

            this.$emit("isValid", result.valid);
          }
        );
      }
    },
    handlePhoneNumber(event) {
      if (event.isValid) {
        this.validatorError = null;
      } else if (event.nationalNumber) {
        this.validatorError = "Phone number must be valid";
      }

      this.$emit("isValid", event.isValid);
      this.$emit("getCountryCode", event.countryCallingCode);
    },
  },
};
</script>

<template>
  <div id="sns-input">
    <template v-if="inputType === 'textfield'">
      <label class="form-control w-full">
        <div v-if="label !== ''" class="label">
          <div class="label-text">
            {{ label }}
          </div>

          <div
            v-if="$attrs.type === 'password'"
            class="label-text-alt password-toggle-label"
            @click="isPasswordVisible = !isPasswordVisible"
          >
            <i :class="`bi-eye${isPasswordVisible ? '-slash' : ''}-fill`" />
            {{ isPasswordVisible ? 'Hide' : 'Show' }}
          </div>
        </div>

        <label
          class="flex items-center gap-2 input input-bordered w-full focus:outline-none focus:border-primary"
          :class="`input-${size}`"
        >
          <div
            v-if="icon !== ''"
            class="bg-transparent"
          >
            <i :class="icon" />
          </div>

          <input
            v-bind="$attrs"
            :value="modelValue"
            :type="type"
            class="w-full"
            :class="`input-${size}`"
            @input="$emit('update:modelValue', $event.target.value)"
            @blur="validateField"
          >
        </label>

        <div v-if="validatorError" class="label">
          <div class="label-text-alt error-message">
            {{ validatorError }}
          </div>
        </div>
      </label>
    </template>

    <div
      v-else-if="inputType === 'phone'"
      class="phone-number-input"
    >
      <maz-phone-number-input
        v-bind="$attrs"
        :model-value="modelValue"
        :size="size"
        :no-formatting-as-you-type="true"
        color="#344cff"
        valid-color="#ced4da"
        error-color="#ced4da"
        no-example
        @input="$emit('update:modelValue', $event.target.value)"
        @update="handlePhoneNumber"
      />

      <div
        v-if="validatorError"
        class="error-message mt-1"
      >
        {{ validatorError }}
      </div>
    </div>
  </div>
</template>

<style lang="scss">
#sns-input {
  .input:focus-within {
    outline: none !important;
    @apply border-primary;
  }

  .phone-number-input {
    .m-phone-number-input {
      width: 100%;

      .m-input-wrapper {
        @apply border border-solid border-accent;
        border-radius: 0.375rem;

        .m-input-input, .m-input-label {
          font-size: 1rem !important;
        }
      }
    }
  }

  label {
    @apply text-neutral;
  }

  .password-toggle-label {
    cursor: pointer;
    @apply text-neutral;

    &:hover {
      @apply text-primary;
    }
  }

  .error-message {
    @apply text-error;
    font-size: 0.75rem;
    line-height: 1rem;
    animation: shake-vertical 100ms ease-in-out 3;
  }

  @keyframes shake-vertical {
    0% {
      transform: translate(1px, 0);
    }
    20% {
      transform: translate(-3px, 0);
    }
    30% {
      transform: translate(3px, 0);
    }
    40% {
      transform: translate(1px, 0);
    }
    60% {
      transform: translate(-3px, 0);
    }
    70% {
      transform: translate(3px, 0);
    }
    100% {
      transform: translate(1px, 0);
    }
  }
}
</style>
