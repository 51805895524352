<script>
import {
  TransitionRoot,
  TransitionChild,
  Dialog as HeadlessDialog,
  DialogPanel,
  DialogTitle,
} from "@headlessui/vue";

export default {
  name: "SnsModal",
  components: {
    HeadlessDialog,
    TransitionRoot,
    TransitionChild,
    DialogPanel,
    DialogTitle,
  },
  props: {
    isModalOn: {
      type: Boolean,
      default: false,
    },
    modalId: {
      type: String,
      default: "sns-modal",
    },
    size: {
      type: String,
      default: "",
    },
  },
  emits: ["close"],
  data() {
    return {
      isOpen: false,
    };
  },
  watch: {
    isModalOn() {
      this.isOpen = this.isModalOn;
    },
  },
  methods: {
    closeModal() {
      this.isOpen = false;
      this.$emit("close");
    },
  },
};
</script>

<template>
  <div :id="modalId">
    <TransitionRoot appear :show="isOpen" as="template">
      <HeadlessDialog as="div" class="relative z-50" @close="closeModal">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-base-content/50" />
        </TransitionChild>

        <div class="fixed inset-0 overflow-y-auto">
          <div
            class="flex h-screen items-center justify-center p-4 text-center"
          >
            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel
                class="
                  w-full md:w-2/4 2xl:w-1/3
                  max-h-[98%]
                  transform rounded-2xl
                  bg-base-100 p-6 text-left align-middle shadow-xl transition-all
                  overflow-auto
                "
                :class="size"
              >
                <DialogTitle
                  as="h3"
                  class="text-lg font-medium leading-6"
                >
                  <slot name="modal-header"/>
                </DialogTitle>

                <div class="mt-2">
                  <slot name="modal-body"/>
                </div>

                <div class="mt-4">
                  <slot name="modal-footer"/>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </HeadlessDialog>
    </TransitionRoot>
  </div>
</template>
