const customTheme = {
  assets: {
    // logo: "LOGO_URL",            // URL for your logo
  },
  colors: {
    // "primary":"#1E88E5",         // Primary color for buttons, links, etc.
    // "primary-content":"#FFFFFF", // Text color on primary elements
    // "accent":"#FFC107",          // Accent color for highlights
    // "neutral":"#9E9E9E",         // Neutral color for borders, dividers, etc.
    // "base-100":"#F5F5F5",        // Main background color
    // "base-200":"#E0E0E0",        // Secondary background color
    // "base-content":"#212121",    // Text color for base backgrounds
    // "success":"#4CAF50",         // Success color for positive actions
    // "success-content":"#FFFFFF", // Text color on success elements
    // "warning":"#FF9800",         // Warning color for alerts and caution
    // "warning-content":"#000000", // Text color on warning elements
    // "error":"#F44336",           // Error color for failed states or errors
    // "error-content":"#FFFFFF"    // Text color on error elements
  },
};

export default customTheme;
