<script>
import ErrorUi from "./ErrorUi";

export default {
  name: "ErrorBoundary",
  components: {
    ErrorUi,
  },
  props: {
    name: {
      type: String,
      default: "VueApp",
    },
  },
  data() {
    return {
      error: false,
    };
  },
  errorCaptured() {
    this.error = true;
  },
};
</script>

<template>
  <div>
    <ErrorUi v-if="error" />
    <slot v-else />
  </div>
</template>
