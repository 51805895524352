<script>
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/vue";
import UserChangePasswordModal from "@/components/profile/UserChangePasswordModal";

export default {
  name: "NavigationUserMenu",
  components: {
    Popover,
    PopoverPanel,
    PopoverButton,
    UserChangePasswordModal,
  },
  data() {
    return {
      isMenuOn: false,
      isPasswordChangeModalOn: false,
    };
  },
  computed: {
    user() {
      return this.$store?.getters?.getUser;
    },
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
    },
  },
};
</script>

<template>
  <div id="navigation-user-menu">
    <Popover class="relative">
      <PopoverButton class="focus-visible:border-none" @click="isMenuOn = !isMenuOn" @blur="isMenuOn = false">
        <div class="flex items-center">
          <div class="basis-auto me-2">
            <div class="bi-person-circle text-3xl" />
          </div>

          <div class="basis-auto me-2">
            <div class="flex font-bold truncate">{{ user.name }}</div>
            <div class="flex text-sm truncate text-neutral">{{ user.email }}</div>
          </div>

          <div class="basis-auto">
            <label class="swap swap-rotate">
              <input v-model="isMenuOn" type="checkbox" />
              <div class="swap-on">
                <i class="bi-chevron-up" />
              </div>

              <div class="swap-off">
                <i class="bi-chevron-down" />
              </div>
            </label>
          </div>
        </div>
      </PopoverButton>

      <PopoverPanel class="absolute right-0 z-10 min-w-52 text-xl">
        <ul class="p-2 shadow menu dropdown-content bg-base-100 rounded-lg w-100">
          <li @click="isPasswordChangeModalOn = true">
            <div class="flex">
              <i class="bi-key" />
              Change Password
            </div>
          </li>

          <li>
            <a :href="'/logout'" @click.prevent="logout">
              <i class="bi-box-arrow-right" />
              Logout
            </a>
          </li>
        </ul>
      </PopoverPanel>
    </Popover>

    <UserChangePasswordModal
      :is-modal-on="isPasswordChangeModalOn"
      @close="isPasswordChangeModalOn = false"
    />
  </div>
</template>
