<script>
import { mapGetters } from "vuex";

export default {
  name: "SidebarMenuLayout",
  data() {
    return {
      productList: [
        {
          id: 1,
          text: "Deepfake Detection",
          icon: "bi-people",
          routerLink: "dashboard.deepfake.detection",
          groupName: "deepfake_detection",
        },
        {
          id: 2,
          text: "Identity Verification",
          icon: "bi-person-vcard",
          routerLink: "dashboard.id.verification",
          groupName: "identity_verification",
        },
        {
          id: 3,
          text: "Document Verification",
          icon: "bi-file-earmark-text",
          routerLink: "dashboard.document.check",
          groupName: "document_verification",
        },
        {
          id: 4,
          text: "Video Liveness",
          icon: "bi-person-bounding-box",
          routerLink: "dashboard.video.liveness",
          groupName: "video_liveness",
        },
      ],
      additionalProductList: [
        {
          id: 5,
          text: "Analytics",
          icon: "bi-graph-up",
          routerLink: "dashboard.analytics.list",
        },
        {
          id: 6,
          text: "Team Management",
          icon: "bi-person-vcard-fill",
          routerLink: "dashboard.team.management",
        },
      ],
      sidebarList: [
        {
          id: 0,
          text: "Dashboard",
          icon: "bi-house",
          routerLink: "dashboard.home",
        }
      ],
    };
  },
  computed: {
    ...mapGetters(["isStaging", "getUser"]),
    user() {
      return this.getUser;
    },
    productAccessGroups() {
      return this.user?.groups?.product_access;
    },
  },
  async created() {
    this.handleProductAccess();
  },
  methods: {
    handleProductAccess() {
      this.productList.forEach((item) => {
        if (this.productAccessGroups && this.productAccessGroups[item.groupName]) {
          this.sidebarList.push(item);
        }
      });

      this.additionalProductList.forEach((item) => {
        this.sidebarList.push(item);
      });

      this.sidebarList = this.sidebarList.sort((a, b) => a.id - b.id);
    },
    isItemActive(item) {
      return this.$route.name.includes(item.routerLink);
    }
  }
};
</script>

<template>
  <div>
    <div v-if="user.id" id="sidebar-menu">
      <router-link
        v-for="(item, index) in sidebarList"
        :key="index"
        :to="{ name: item.routerLink }"
      >
        <div
          class="flex justify-center items-center sidebar-list-item"
          :class="{'sidebar-list-item-active': isItemActive(item)}"
        >
          <div class="basis-full xl:basis-1/6 xl:!ml-0 text-center sidebar-list-item-icon">
            <i :class="item.icon" />

            <div class="block xl:hidden sidebar-list-item-subtitle">
              {{ item.text }}
            </div>
          </div>

          <div class="hidden xl:block basis-5/6">
            {{ item.text }}
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<style lang="scss" scoped>
#sidebar-menu {
  font-size: 1em;
  @apply text-neutral;
  margin-right: 1rem;
  transition: background-color .3s;

  @media screen and (max-width: 1280px) {
    margin-right: 0 !important;

    .sidebar-list-item {
      &-active {
        margin-right: 1rem;

        .sidebar-list-item-icon, .sidebar-list-item-subtitle {
          margin-left: 1rem;
        }
      }
    }
  }

  .sidebar-list-item {
    padding: 12px 0 12px 0;
    @apply border-l-4 border-solid border-base-100;

    &-icon {
      font-size: 1.2em;
    }

    &-subtitle {
      font-size: 12px;
    }

    &-active {
      border-radius: 0 8px 8px 0;
      @apply border-l-4 border-solid border-primary text-primary bg-primary bg-opacity-15;

    }

    &:hover {
      @apply border-l-4 border-solid border-primary text-primary;
      transition: color .3s;
      transition: border .3s;
    }

    & {
      transition: color .3s;
      transition: border .3s;
    }
  }
}
</style>
