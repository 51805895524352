<script>
import { createPopper } from "@popperjs/core";

export default {
  name: "SnsPopper",
  props: {
    placement: {
      type: String,
      default: "top",
    },
    showContent: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    popperConfig() {
      return {
        placement: this.placement,
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, 8],
            },
          },
        ],
      };
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.initPopper();
    });
  },
  methods: {
    initPopper() {
      createPopper(this.$refs.element, this.$refs.tooltip, this.popperConfig);
    },
  },
};
</script>

<template>
  <div id="sns-popper">
    <div ref="element" class="element" aria-describedby="tooltip">
      <slot name="element"/>
    </div>

    <div ref="tooltip" :class="showContent ? 'tooltip' : ''" role="tooltip">
      <template v-if="showContent">
        <slot name="content"/>
        <div class="tooltip-arrow" data-popper-arrow />
      </template>
    </div>
  </div>
</template>

<style lang="scss">
#sns-popper {
  $tooltip-border-color: #e0e0e0;

  .tooltip {
    position: absolute;
    visibility: hidden;
  }

  .tooltip-arrow {
    position: absolute;
    visibility: hidden;
  }

  &:hover {
    z-index: 12 !important;

    .tooltip {
      visibility: visible;
    }

    .tooltip-arrow {
      visibility: visible;
    }
  }

  .tooltip {
    @apply bg-base-100;
    display: inline-block;
    font-weight: bold;
    font-size: 13px;
    border-radius: 4px;
    border: 1px solid $tooltip-border-color;
    z-index: 12;
    padding: .5rem;
    border-radius: .5rem;
  }

  .tooltip-arrow {
    &::before {
      @apply bg-base-100;
      border-bottom: 2px solid $tooltip-border-color;
      border-right: 2px solid $tooltip-border-color;
      position: absolute;
      width: 10px;
      height: 10px;
      z-index: 12;
      content: '';
      transform: rotate(45deg);
    }
  }

  .tooltip[data-popper-placement^='top'] > .tooltip-arrow {
    bottom: 2px;
  }

  .tooltip[data-popper-placement^='bottom'] > .tooltip-arrow {
    top: -8px;

    &::before {
      border-bottom: none;
      border-right: none;
      border-top: 2px solid $tooltip-border-color;
      border-left: 2px solid $tooltip-border-color;
    }
  }

  .tooltip[data-popper-placement^='left'] > .tooltip-arrow {
    right: 4px;

    &::before {
      border-left: none;
      border-bottom: none;
      border-right: 2px solid $tooltip-border-color;
      border-top: 2px solid $tooltip-border-color;
    }
  }

  .tooltip[data-popper-placement^='right'] > .tooltip-arrow {
    left: -8px;

    &::before {
      border-right: none;
      border-top: none;
      border-left: 2px solid $tooltip-border-color;
      border-bottom: 2px solid $tooltip-border-color;
    }
  }
}
</style>
