<script>
import ErrorBoundary from "@/components/error-boundary/ErrorBoundary";
import NavigationLayout from "@/views/layouts/NavigationLayout";
import SidebarLayout from "@/views/layouts/SidebarLayout";

export default {
  name: "DashboardLayout",
  components: {
    ErrorBoundary,
    NavigationLayout,
    SidebarLayout,
  },
  watch: {
    $route() {
      document.body.style.overflow = "initial";
      document.body.style.maxHeight = "initial";
    },
  },
};
</script>

<template>
  <div>
    <ErrorBoundary :name="$route.path">
      <NavigationLayout />
      <main class="dashboard-layout">
        <SidebarLayout />
        <div class="dashboard-layout-inner">
          <div class="dashboard-layout-container">
            <router-view :key="$route.path" />
          </div>
        </div>
      </main>
    </ErrorBoundary>
  </div>
</template>

<style lang="scss" scoped>
.dashboard-layout {
  @apply bg-base-200 bg-opacity-80;
  min-height: 100vh;
  transition: background-color .3s;

  .dashboard-layout-inner {
    width: calc(100% - $sidebar-width-lg);
    margin-left: auto;

    @media screen and (max-width: 1320px) and (min-width: $screen-size-tablet) {
      width: calc(100% - $sidebar-width-md);
    }

    @media screen and (max-width: $screen-size-tablet) {
      width: initial;
    }

    .dashboard-layout-container {
      margin: 0 auto;
      padding: 2rem 1rem ;
      max-width: 1920px;
    }
  }
}
</style>
