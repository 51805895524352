<script>
import { mapActions, mapGetters } from "vuex";
import ErrorBoundary from "@/components/error-boundary/ErrorBoundary";
import SnsNotification from "@/components/SnsNotification.vue";
import theme from "@/constants/theme.js";

export default {
  name: "App",
  components: {
    ErrorBoundary,
    SnsNotification,
  },
  metaInfo: {
    title: "Sensity - End-To-End Deepfake Detection",
    description: "AI-Powered video, image, audio verification",
    meta: [
      // https://github.com/DeeptraceLabs/platform/issues/1353
      { name: "robots", content: "noindex" },
    ],
  },
  data() {
    return {
      theme,
    };
  },
  computed: {
    ...mapGetters(["isAuth", "getTheme"]),
  },
  watch: {
    isAuth() {
      this.initialize();
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    ...mapActions(["fetchUser", "fetchUserUsage", "setTheme"]),
    initialize() {
      if (this.isAuth) {
        this.fetchUser();
        this.fetchUserUsage();

        if (theme.assets?.logo) {
          document.documentElement.setAttribute("data-theme", "custom");
        } else {
          const theme = localStorage.getItem("theme") || this.getTheme;
          document.documentElement.setAttribute("data-theme", theme);
          this.setTheme(theme);
        }
      }
    },
  },
};
</script>

<template>
  <div>
    <ErrorBoundary>
      <router-view />
    </ErrorBoundary>

    <SnsNotification
      group="video-notify"
      :duration="-1"
      ignore-duplicates
    />

    <SnsNotification
      group="notify"
    />
  </div>
</template>
