export default function unauthorizedMiddleware({
  to,
  next,
  store,
  nextMiddleware,
}) {
  console.info("<unauthorizedMiddleware> isAuth: ", store.getters.isAuth);

  if (store.getters.isAuth) {
    return next(to.query.redirect || { name: "dashboard.home" });
  }

  return nextMiddleware();
}
