<script>
import { mapGetters } from "vuex";
import theme from "@/constants/theme.js";

export default {
  name: "SnsLogo",
  props: {
    imageClass: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      theme,
    };
  },
  computed: {
    ...mapGetters(["getTheme"]),
    customThemeLogo() {
      return this.theme?.assets?.logo;
    },
    isDarkTheme() {
      return this.getTheme === "sensity_dark";
    },
  },
}
</script>

<template>
  <div class="sns-logo">
    <router-link :to="{ name: 'dashboard.home' }" title="Sensity">
      <img v-if="customThemeLogo" :src="customThemeLogo" class="w-full h-auto max-h-20" :class="imageClass">
      <img v-else-if="isDarkTheme" src="@/assets/images/logo_white.svg" class="w-3/5" :class="imageClass">
      <img v-else src="@/assets/images/logo.svg" class="w-3/5" :class="imageClass">
    </router-link>
  </div>
</template>
