<script>
import ErrorUi from "./ErrorUi";

export default {
  name: "NotFound",
  components: {
    ErrorUi,
  },
  props: {},
};
</script>

<template>
  <ErrorUi />
</template>
