<script>
export default {
  name: "SnsAlert",
  props: {
    type: {
      type: String,
      default: "warning",
    },
    header: {
      type: String,
      default: "Warning",
    },
    content: {
      type: String,
      default: "Something went wrong !",
    },
    closeable: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["cancel"],
  data () {
    return {
      isVisible: true,
    };
  },
  mounted() {
    this.isVisible = true;
  },
  methods: {
    closeAlert() {
      this.$emit("cancel");
      this.isVisible = false;
    },
  },
};
</script>

<template>
  <div id="sns-alert">
    <div
      v-if="isVisible"
      class="alert flex"
      :class="type"
      role="alert"
    >
      <div class="basis-full">
        <div class="flex">
          <div class="basis-5/6">
            <div class="font-bold text-lg">{{ header }}</div>
          </div>

          <div
            v-if="closeable"
            class="basis-1/6 text-end cursor-pointer"
            @click="closeAlert"
          >
            <i class="bi-x-lg"/>
          </div>
        </div>

        <div class="flex">
          {{ content }}
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
#sns-alert {
  text-align: left;
}
</style>
