import { createStore } from "vuex";
import axios from "../plugins/axios";
import formatQueryParams from "../utils/string";
import { apiUrlMap, onPremUrlMap } from "../constants/apiVersions";

const apiUrl = import.meta.env.VITE_APP_IS_ONPREM === "on" ? onPremUrlMap : apiUrlMap;

// Create a new store instance.
const store = createStore({
  state () {
    return {
      token: localStorage.getItem("token") || "",
      user: localStorage.getItem("user") || "{}",
      userUsage: null,
      navigationOpen: false,
      redirectUrl: "",
      isExplanationVideoSubmitted: false,
      theme: "sensity_light",
    };
  },
  getters: {
    getUser: (state) => {
      let { user } = state;
      if (typeof user !== "object") {
        user = JSON.parse(user);
      }
      return user;
    },
    getUserUsage: (state) => {
      let { userUsage } = state;
      if (typeof userUsage !== "object") {
        userUsage = JSON.parse(userUsage);
      }
      return userUsage;
    },
    licenceExpired: (state) => {
      let { user } = state;
      if (typeof user !== "object") {
        user = JSON.parse(user);
      }
      return user.is_licence_ended;
    },
    isTrialEnded: (state, getters) => {
      let { user, userUsage } = state;
      let trialEnded = false;

      if (
        !getters.isOnPrem &&
        (!user.is_dev || !user.is_staff) &&
        (
          user.is_licence_ended
          ||
          (userUsage?.licence_monthly_limit <= userUsage?.usage_amount?.monthly)
          ||
          (user.demo_user && new Date(userUsage?.licence_end_date) < new Date())
        )
      ) {
        trialEnded = true;
      }

      return trialEnded;
    },
    getNavigationOpen: (state) => state.navigationOpen,
    isAuth: (state) => !!state.token.length,
    isStaging() {
      return import.meta.env.VITE_APP_STAGING_ONLY === "on";
    },
    isOnPrem() {
      return import.meta.env.VITE_APP_IS_ONPREM === "on";
    },
    getRedirectUrl: (state) => state.redirectUrl,
    hasAccessToProduct: () => (product) => {
      const user = JSON.parse(localStorage.getItem("user"));
      return user.groups && user.groups.product_access && user.groups.product_access[product];
    },
    getExplanationVideoSubmittedStatus: (state) => state.isExplanationVideoSubmitted,
    getTheme: (state) => state.theme,
  },
  mutations: {
    setToken: (state, payload) => {
      localStorage.setItem("token", payload);
      state.token = payload;
    },
    setUser: (state, payload) => {
      localStorage.setItem("user", JSON.stringify(payload));
      state.user = payload;
    },
    setUserUsage: (state, payload) => {
      state.userUsage = payload;
    },
    clearStorage() {
      localStorage.removeItem("user");
      localStorage.removeItem("id-list-results");
      localStorage.removeItem("liveness-list-results");
      localStorage.removeItem("deepfake-list-results");
      localStorage.removeItem("document-list-results");
    },
    setNavigationOpen: (state, payload) => {
      state.navigationOpen = payload;
    },
    setRedirectUrl: (state, payload) => {
      state.redirectUrl = payload;
    },
    setExplanationVideoSubmittedStatus: (state, payload) => {
      state.isExplanationVideoSubmitted = payload;
    },
    setTheme: (state, payload) => { state.theme = payload; },
  },
  actions: {
    setRedirectUrl({ commit }, payload) {
      commit("setRedirectUrl", payload);
    },
    async login({ commit}, user) {
      const response = await axios.post(apiUrl.login.POST, user);

      const { data } = response;
      const { token } = data;

      localStorage.removeItem("type");

      commit("setToken", token);

      axios.defaults.headers.common.Authorization = `Token ${token}`;

      return data;
    },
    logout({ commit }) {
      return new Promise(() => {
        commit("setToken", "");
        commit("clearStorage");

        if (localStorage.getItem("token") !== null) {
          localStorage.removeItem("type");
          localStorage.removeItem("token");
          window.location.href = "/login";
        }
      });
    },
    register(_, user) {
      return new Promise((resolve, reject) => {
        axios({
          url: apiUrl.signup.POST,
          data: user,
          method: "POST",
        })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    passwordRecovery( _,email) {
      return new Promise((resolve, reject) => {
        axios({
          url: apiUrl.passwordReset.POST,
          data: {
            email,
          },
          method: "POST",
        })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    confirmRegistration(_,{ uid, token }) {
      return new Promise((resolve, reject) => {
        axios({
          url: apiUrl.activate.POST,
          data: {
            uid,
            token,
          },
          method: "POST",
        })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    confirmNewPassword(_, { uid, token, password, confirmPassword }) {
      return new Promise((resolve, reject) => {
        axios({
          url:apiUrl.passwordResetConfirm.POST,
          data: {
            uid,
            token,
            new_password1: password,
            new_password2: confirmPassword,
          },
          method: "POST",
        })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    changePassword(_,{ passwordOld, passwordNew, passwordConfirm }) {
      return new Promise((resolve, reject) => {
        axios({
          url: apiUrl.passwordChange.POST,
          data: {
            password_old: passwordOld,
            password_new: passwordNew,
            password_confirm: passwordConfirm,
          },
          method: "POST",
        })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    async fetchUser({ commit }) {
      let { data:user } = await axios.get(apiUrl.user.GET.DETAIL);
      commit("setUser", user);
    },
    async fetchUserUsage({ commit }) {
      try {
        let { data:userUsage } = await axios.get(apiUrl.userUsage.GET.DETAIL);
        const { data:submissionCount } = await axios.get(apiUrl.submissions.GET.DETAIL);
        userUsage.usage_amount = {
          ...userUsage.usage_amount,
          ...submissionCount,
        };

        commit("setUserUsage", userUsage);
      } catch (error) {
        console.error("Error occured fetching user usage: " + error);
      }
    },
    getUsers(_,id) {
      return new Promise((resolve, reject) => {
        axios({
          url: `${apiUrl.users.GET.DETAIL}${id}`,
          method: "GET",
        })
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    sendSlackMessage(_, { message, url }) {
      return new Promise((resolve, reject) => {
        axios({
          url: apiUrl.sendSlackMessage.POST,
          method: "POST",
          data: {
            message,
            url
          },
        })
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    sendServiceMedia(_,{ data, onUploadProgress }) {
      return axios.post(apiUrl.serviceMedia.POST, data, { onUploadProgress });
    },
    fetchServiceMedia(_, params = {}) {
      return axios.get(apiUrl.serviceMedia.GET.LIST, { params });
    },
    fetchServiceMediaById(_,id) {
      return axios.get(`${apiUrl.serviceMedia.GET.DETAIL}${id}/`);
    },
    fetchServiceMediaPdfById(_,id) {
      return axios.get(`${apiUrl.serviceMedia.GET.DETAIL}${id}/pdf/`, { responseType: "arraybuffer" });
    },
    fetchServiceJobById(_,id) {
      return axios.get(`${apiUrl.serviceJob.GET.DETAIL}${id}/`);
    },
    fetchReportJobs(_, params) {
      return axios.get(`${apiUrl.reportList.GET.LIST}?${formatQueryParams(params.pagination)}${params.queryString}`);
    },
    fetchTargetIndustries() {
      return axios.get(apiUrl.targetIndustries.GET.LIST);
    },
    getDuplicateCheckJob(_, jobId) {
      return new Promise((resolve, reject) => {
        axios({
          url: `${apiUrl.duplicateCheckJob.GET.DETAIL}${jobId}/`,
          method: "GET",
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
      });
    },
    sendContactEmail(_, params) {
      return axios.post(apiUrl.contactUs.POST, params);
    },
    fetchTeamMembers() {
      return axios.get(apiUrl.teamMembers.GET.LIST);
    },
    fetchTeamRoles() {
      return axios.get(apiUrl.teamRoles.GET.LIST);
    },
    updateTeamMember(_,params) {
      return axios.patch(apiUrl.teamMember.PATCH, params);
    },
    inviteNewTeamMember(_, params) {
      return axios.put(apiUrl.teamMember.PUT, params);
    },
    createTeam(_,params) {
      return axios.post(apiUrl.team.POST, params);
    },
    updateTeam(_,params) {
      return axios.patch(apiUrl.team.PATCH, params);
    },
    fetchTeam() {
      return axios.get(apiUrl.team.GET.LIST);
    },
    createExplanationVideo(_, params) {
      return axios.post(apiUrl.explanation.POST, params);
    },
    fetchExplanationVideo(_, params) {
      return axios.get(apiUrl.explanation.GET.DETAIL, { params });
    },
    deleteReportsById(_, params) {
      return axios.post(apiUrl.report.DELETE, params);
    },
    setExplanationVideoSubmittedStatus({ commit }, payload) {
      commit("setExplanationVideoSubmittedStatus", payload);
    },
    setTheme({ commit }, payload) {
      commit("setTheme", payload);
      localStorage.setItem("theme", payload);
    }
  },
});

export default store;
