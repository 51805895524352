<script>
import { mapActions, mapGetters } from "vuex";
import SnsPopper from "@/components/SnsPopper.vue";
import NavigationUserMenu from "@/components/navigation/NavigationUserMenu";
import theme from "@/constants/theme.js";

export default {
  name: "NavigationLayout",
  components: {
    SnsPopper,
    NavigationUserMenu,
  },
  data() {
    return {
      theme,
    };
  },
  computed: {
    ...mapGetters(["getUserUsage", "isAuth", "getTheme", "isStaging", "isOnPrem"]),
    navigationOpen: {
      get() {
        return this.$store.getters.getNavigationOpen;
      },
      set(payload) {
        this.$store.commit("setNavigationOpen", payload);
      },
    },
    isDemoUser() {
      return this.getUserUsage?.demo_user;
    },
    monthlyLimit() {
      return this.getUserUsage?.licence_monthly_limit;
    },
    usageForCurrentMonth() {
      return this.getUserUsage?.usage_amount?.monthly || 0;
    },
    isLoggedIn() {
      return this.isAuth;
    },
    trialUsagePercentage() {
      return (this.usageForCurrentMonth / this.monthlyLimit) * 100;
    },
    customThemeLogo() {
      return this.theme?.assets?.logo;
    },
    isDarkTheme() {
      return this.getTheme === "sensity_dark";
    },
  },
  methods: {
    ...mapActions(["setTheme"]),
    logoClick() {
      if (window.innerWidth > 767) {
        this.$router.push({ name: "dashboard.home" });
      } else {
        this.navigationOpen = !this.navigationOpen;
      }
    },
    toggleTheme() {
      const theme = this.getTheme;
      const newTheme = theme === "sensity_dark" ? "sensity_light" : "sensity_dark";

      document.documentElement.setAttribute("data-theme", newTheme);
      this.setTheme(newTheme);
    },
  },
};
</script>

<template>
  <div
    id="sensity-navbar"
    class="navigation"
  >
    <div class="flex justify-between items-center p-2">
      <div class="basis-auto md:hidden">
        <a
          href="/"
          class="navigation-logo w-3/5 flex items-center"
          title="Sensity"
          @click.prevent="logoClick"
        >
          <img v-show="customThemeLogo" :src="customThemeLogo">
          <img v-show="!customThemeLogo && !isDarkTheme" id="sensity-logo" src="@/assets/images/logo.svg">
          <img v-show="!customThemeLogo && isDarkTheme" src="@/assets/images/logo_white.svg" >
        </a>
      </div>

      <template v-if="isLoggedIn">
        <div v-if="isDemoUser" class="basis-1/6">
          <sns-popper
            :show-content="true"
            class="trial-progress-tooltip"
          >
            <template #element>
              Credits:
              <span class="font-bold">
                {{ usageForCurrentMonth }}/{{ monthlyLimit }}
              </span>

              <progress class="progress progress-primary w-56" :value="trialUsagePercentage" max="100"/>
            </template>

            <template #content>
              You are using trial version of the platform.
              Upgrade to the premium plan to use the platform without restrictions.
            </template>
          </sns-popper>
        </div>

        <div class="basis-auto"/>

        <div class="basis-auto">
          <div class="flex">
            <label
              v-if="!isOnPrem"
              class="swap swap-rotate w-full p-2 mr-4"
              :class="{ 'swap-active': getTheme === 'sensity_dark' }"
              @click="toggleTheme"
            >
              <i class="bi-sun swap-off" />
              <i class="bi-moon swap-on" />
            </label>

            <NavigationUserMenu />
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<style lang="scss">
#sensity-navbar {
  padding-left: 0;
  @apply bg-base-100;

  .trial-progress {
    &-tooltip {
      .tooltip {
        font-size: 1rem !important;
        padding: .5rem;
        border-radius: .5rem;
        max-width: 60% !important;
        position: fixed;
        z-index: 10 !important;
      }
    }
  }
}

.navigation {
  width: calc(100% - $sidebar-width-lg);;
  position: sticky;
  top: 0;
  z-index: 11;
  margin-left: auto;
  transition: all 0.3s;

  @media screen and (max-width: 1320px) and (min-width: 768px) {
    width: calc(100% - $sidebar-width-md);
  }

  @media screen and (max-width: 767px) {
    width: initial;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.14);
  }
}
</style>
