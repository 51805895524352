<script>
export default {
  name: "ErrorUi",
  data() {
    return {
      countDown: 10,
    }
  },
  watch: {
    countDown(payload) {
      if (payload === 0) {
        this.redirectToHome()
      }
    },
  },
  created() {
      this.countDownTimer();
  },
  methods: {
    async redirectToHome() {
      await this.$router.push({ name: "dashboard.home" });
      await this.$router.go({ name: "dashboard.home" });
    },
    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1
          this.countDownTimer()
        }, 1000)
      }
    },
  },
}
</script>

<template>
  <div class="error-container">
    <div class="error-inner">
      <div class="error-content">
        <h1 class="error-heading">
          Oops!
        </h1>
        <p>
          Something went wrong, our team is investigating the
          issue.
        </p>
        <p class="d-inline">
          The page will redirect to the Home page in
          <strong>{{ countDown }}</strong> seconds, or you can go
          back
        </p>

        <button class="error-button" @click="redirectToHome">
          now.
        </button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.error-container {
  position: relative;
  display: flex;
  height: 100vh;
  padding: 40px;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to bottom, #bccee0 1%, #f5f8f9 58%);

  &:before,
  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 100%;
    width: 200px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    z-index: 0;
  }

  &:after {
    background-image: url("@/assets/images/face_r@2x.png");
    right: 0;
  }

  &:before {
    background-image: url("@/assets/images/face_l@2x.png");
    left: 0;
  }

  .error-inner {
    @apply shadow shadow-accent bg-base-100;
    border-radius: 0.18rem;
    padding: 40px;
    flex: 1;
    width: 100%;
    height: 100%;
    max-height: 500px;
    max-width: 800px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;

    .error-content {
      max-width: calc(100% - 50px);
      text-align: center;

      &>* {
        display: block;
        margin: 0 auto;
      }

      .error-heading {
        @apply text-primary text-opacity-75;
        margin-bottom: 64px;
        font-size: 64px;
        font-weight: 600;
      }

      .error-button {
        @apply text-primary;
        border: none;
        display: inline-block;
        padding: 0;
      }
    }
  }
}
</style>
