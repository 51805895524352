<script>
import ErrorBoundary from "@/components/error-boundary/ErrorBoundary";

export default {
  name: "CleanLayout",
  components: {
    ErrorBoundary,
  },
};
</script>

<template>
  <ErrorBoundary :name="$route.path">
    <router-view />
  </ErrorBoundary>
</template>
