<script>
export default {
  name: "SnsNotification",
  methods: {
    redirectToReport(event) {
      if (event?.item?.data?.reportId) {
        this.$router.push(`/deepfake-detection/report/${event.item.data.reportId}`);
      }

      event.close();
    },
    notfificationClass(event) {
      console.log(event);
      if (event.item.type === "success") {
        return "!bg-success";
      } else if (event.item.type === "warn") {
        return "!bg-warning !text-black";
      } else if (event.item.type === "error") {
        return "!bg-error";
      } else if (event.item.type === "info") {
        return "!bg-primary text-white";
      }
    },
  },
};
</script>

<template>
  <notifications
    :v-bind="$attrs"
    group="notify"
    position="bottom right"
    class="cursor-pointer"
    width="auto"
    :speed="500"
    :duration="5000"
  >
    <template #body="props">
      <div
        class="flex min-w-80 w-96 bg-success bg-opacity-75 text-white p-4 rounded-lg m-1"
        :class="notfificationClass(props)"
      >
        <div class="basis-full">
          <div class="flex">
            <div class="basis-5/6" @click="redirectToReport(props)">
              <div class="font-bold text-lg">{{ props.item.title }}</div>
            </div>

            <div
              class="basis-1/6 text-end cursor-pointer"
              @click="props.close"
            >
              <i class="bi-x-lg"/>
            </div>
          </div>

          <div class="flex" @click="redirectToReport(props)">
            {{ props.item.text }}
          </div>
        </div>
      </div>
    </template>
  </notifications>
</template>
