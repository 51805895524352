import globalAxios from "axios";
import store from "../store";

const axios = globalAxios.create({
  baseURL: import.meta.env.VITE_APP_ROOT_API,
});

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const { status } = error.response;

    const loggedUser = store.getters.getUser;

    if (!error.response) {
      if (!loggedUser) {
        window.location.href = "/login";
        localStorage.clear();
      }

      throw error;
    }

    const errResponseUrl = error.response.request.responseURL;
    const fromUrl = errResponseUrl.split("/").pop();
    const nonRedirectUrls = [
      "login",
      "password-recovery",
      "activate",
      "confirm-new-password",
    ];

    if (
      // status === 403 ||
      (status === 401 && !nonRedirectUrls.includes(fromUrl))
    ) {
      window.location.href = "/login";
      localStorage.clear();
    }

    throw error;
  }
);

export default axios;
